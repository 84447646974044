import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const AboutPage = () => (
  <Layout>
    <SEO title="Om sidan" />
    <h1>Om sidan</h1>
    <p>Välkommen till den här sidan.</p>
  </Layout>
)

export default AboutPage
